<template>
  <div class="my-page-payment my-page">
    <div class="wrapper">
      <CreditCard :removable="true" :temp="false"/>
    </div>
    <ul class="guide bottom tight font-sm">
      <li>입력된 지불 정보는 오마이컴퍼니 프로젝트 후원 시에 사용됩니다. 성공해야 리워드 방식의 프로젝트에 후원했을 경우 목표액 달성 시 펀딩 마감일 이후 2일 뒤에 결제가 이뤄지며, 무조건 리워드 방식의 프로젝트에 후원할 경우 간편카드결제 시에 사용됩니다.</li>
      <li>입력 가능한 지불 정보는 국내 발급 카드(신용카드 및 체크카드)에 한하며 해외에서 발급된 카드는 등록이 제한됩니다.</li>
      <li>현재 아멕스카드(카드 번호 15자리)는 결제가 불가합니다.</li>
      <li>등록된 카드 정보는 암호화하여 저장되며 서버와 통신시 SSL 보안 프로토콜이 적용됩니다.</li>
      <li>카드 정보를 변경할 경우 이전 카드 정보는 삭제됩니다.</li>
      <li>성공해야 리워드 방식의 프로젝트에 후원했을 경우, 지불 정보에 등록한 체크카드에 잔액이 없거나 기타 사유로 결제가 진행되지 않는다면 펀딩 마감일 이후 6일간 결제를 문자 또는 이메일로 요청합니다.</li>
      <li>만일 재결제 요청 기간동안 카드 잔액 및 지불 정보가 확인되지 않는다면, 최종 후원자에서 제외되며 리워드를 받으실 수 없습니다.</li>
      <li>지불 정보 삭제를 원하시는 분은 "지불 정보 삭제"를 클릭해주세요. 삭제 작업에 어려움이 있는 경우 omc@ohmycompany.com 메일로 문의주시길 바랍니다.</li>
    </ul>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import Number from "@/components/Number";
import CreditCard from "@/components/CreditCard";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "pageMyPagePayment";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {CreditCard, Number},
  props: {
    menu: Object
  },
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "지불 정보");
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-payment {
  > .wrapper {
    max-width: $px470;
    margin: 0 auto $px50 auto;
  }
}
</style>