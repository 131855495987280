<template>
  <div class="credit-card" :class="{skeleton:!state.loaded}">
    <div class="wrapper">
      <div class="name font-md">
        <span>{{ state.card.cardNum ? state.card.cardName : "결제 카드" }}</span>
      </div>
      <div class="silver"></div>
      <div class="rect font-sm">
        <span v-if="state.form.cardNumber">입력 완료</span>
        <span v-else-if="state.card.cardNum">등록 완료</span>
        <span v-else>{{ temp ? "카드 정보를 입력해주세요." : "카드를 등록해주세요." }}</span>
      </div>
      <div class="num font-lg">
        <span v-if="state.form.cardNumber">{{ state.form.cardNumbers[0] }} **** **** **{{ state.form.cardNumbers[3].substr(2) }}</span>
        <span v-else-if="state.card.cardNum">{{ state.card.cardNum }} **** **** **{{ state.card.endCardNum }}</span>
        <span v-else>(N/A)</span>
      </div>
      <div class="date">
        <span>
          <span class="font-sm">VALID THRU </span>
          <span class="font-lg">** **</span>
        </span>
      </div>
      <div class="actions">
        <template v-if="state.card.cardNum">
          <button type="button" class="btn btn-outline-light font-sm" @click="change()">{{ temp ? "직접 입력" : "변경하기" }}</button>
          <button type="button" class="btn btn-outline-light font-sm" @click="remove()" v-if="removable">삭제하기</button>
        </template>
        <button type="button" class="btn btn-outline-light font-sm" @click="add()" v-else>{{ temp ? "입력하기" : "등록하기" }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "componentCreditCard";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    removable: Boolean,
    temp: Boolean,
  },
  setup(props) {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      loaded: false,
      card: {
        cardOwner: "Wait a moment",
        cardName: "",
        cardNum: "",
        endCardNum: "",
      },
      form: {
        birth: "",
        cardNumber: "",
        cardNumbers: ["", "", "", ""],
        cardPassword: "",
        expireMonth: "",
        expireYear: "",
      }
    });

    const update = (params) => {
      if (params) {
        state.form = params;
      } else {
        load();
      }
    };

    const add = () => {
      store.commit("openModal", {
        name: "CreditCard",
        params: {
          temp: props.temp,
          form: state.form
        },
        callback: `${component.name}.update`
      });
    };

    const change = () => {
      store.commit("openModal", {
        name: "CreditCard",
        params: {
          temp: props.temp,
          form: state.form
        },
        callback: `${component.name}.update`
      });
    };

    const remove = () => {
      store.commit("confirm", {
        message: "카드 정보를 삭제하시겠습니까?",
        allow() {
          http.delete("/api/payments/" + store.state.account.memberSeq).then(({data}) => {
            if (data.code === "406 NOT_ACCEPTABLE") {
              return store.commit("setSwingMessage", data.message);
            }

            store.commit("setSwingMessage", data.message);
            load();
          }).catch(httpError());
        }
      });
    };

    const load = () => {
      state.loaded = false;
      http.get("/api/payments/customers").then(({data}) => {
        state.loaded = true;

        if (data.code === "200 OK") {
          state.card.cardOwner = store.state.account.memberName;
          state.card.cardName = data.body.response["card_name"];
          state.card.cardNum = data.body.cardNum;
          state.card.endCardNum = data.body.endCardNum;
        } else {
          state.card.cardOwner = "";
          state.card.cardName = "";
          state.card.cardNum = "";
          state.card.endCardNum = "";
        }
      }).catch(httpError(() => {
        state.loaded = true;
      }));
    };

    return {component, state, update, add, change, remove};
  }
});
</script>

<style lang="scss" scoped>
.credit-card {
  background: #49545f;
  color: #fff;
  position: relative;
  padding-top: 62%;
  border-radius: $px4;
  border: $px1 solid #eee;

  > .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .name {
      padding: $px20 $px25;
      letter-spacing: $px1;
    }

    .silver {
      height: $px60;
      background: $colorBackground;
    }

    .rect {
      padding: $px25 $px25 $px10 $px25;
    }

    .num {
      padding: $px3 $px25 0 $px25;
      color: #ddd;
    }

    .date {
      padding: $px8 $px25;
      color: #ddd;

      span {
        margin-right: $px10;
        vertical-align: middle;
      }
    }

    .actions {
      position: absolute;
      right: $px25;
      bottom: $px25;
      width: $px80;

      button {
        width: 100%;
        margin: $px5 0;
        padding: $px10 $px5;
      }
    }
  }

  &.skeleton {
    .name > span,
    .rect > span,
    .num > span,
    .date > span,
    .actions button {
      @include skeleton;
    }
  }

  @media (max-width: 767px) {
    padding-top: 0;

    .wrapper {
      position: static;

      .name {
        padding: $px15 $px18;
        font-size: $px16 !important;
      }

      .silver {
        height: $px46;
      }

      .rect {
        padding: $px18 $px18 $px5 $px18;
      }

      .num {
        padding: $px3 $px18 0 $px18;
        font-size: $px18 !important;
      }

      .date {
        padding: $px5 $px18 $px15 $px18;
      }

      .actions {
        right: $px18;
        bottom: $px11;
        width: $px70;

        button {
          font-size: $px12 !important;
        }
      }
    }
  }
}
</style>